/**
 * @file Projects2010.js
 * @author Martin Kubička (martinkubicka22@gmail.com)
 * @date 25.01.2023
 * 
 * @brief Declaration of projects between 2010-2015 page component.
 */

import React, {useEffect} from 'react';
import "./projects-list.css"
import RodinnyDom1 from '../../images/projects/rodinnydom1t.jpg';
import PolusTowers from '../../images/projects/polust.jpg';
import Brectanova from '../../images/projects/Brectanova/brectanovatt.jpg';

function Projects2010() { 
  useEffect(() => {
    function handleResize() {
      const textHeight = document.querySelector('hr').offsetWidth;
      let images = document.querySelectorAll('.hover-blur a');
      
      const mediaQuery = window.matchMedia("(min-width: 900px)");
        
      if (mediaQuery.matches) {
        for(let i = 0; i < images.length; i++) {
            images[i].style.width = textHeight/3 -16+ 'px';
        }
      } else {
        for(let i = 0; i < images.length; i++) {
            images[i].style.width = textHeight + 'px';
        }
      }
}

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    }
}, []);

return (
  <div>
    <div className="row projects-list-img-div">

            <a className="projects-list-a" href="/projekty/rodinny-dom-1">
                <div className="hover-blur">
                <a href="/projekty/rodinny-dom-1" title="">
                    <img src={RodinnyDom1} alt="" className="img-fluid"/>
                    <h2><span className="text-white">Rodinný dom I</span></h2>
                </a>
                </div>
            </a>

            <a className="projects-list-a" href="/projekty/polus-towers">
                <div className="hover-blur">
                <a href="/projekty/polus-towers" title="">
                    <img src={PolusTowers} alt="" className="img-fluid"/>
                    <h2><span className="text-white">Polus Towers</span></h2>
                </a>
                </div>
            </a>

            <a className="projects-list-a" href="/projekty/brectanova">
                <div className="hover-blur">
                <a href="/projekty/brectanova" title="">
                    <img src={Brectanova} alt="" className="img-fluid"/>
                    <h2><span className="text-white">Interiér „penthouse“</span></h2>
                </a>
                </div>
            </a>
    </div>
  </div>

  
);
  }
  export default Projects2010;

/*** End of Projects2010.js ***/