/**
 * @file Quotes.js
 * @author Martin Kubička (martinkubicka22@gmail.com)
 * @date 25.01.2023
 * 
 * @brief Declaration of quotes component at home page.
 */

import React, {useState} from 'react'
import "./quotes.css"
import Fade from 'react-reveal/Fade';
import {Waypoint} from 'react-waypoint';

const QuotesArr= ["I was born into a family of builders and this given me special relationship with the art of doing.", "I don't know why people hire architects and then tell them what to do.", "98% of what gets built today is shit.", "Architecture serves to create a poetic situation, to bring forth the emotion that exists in everyday life.", "Although, now, people think I am an environmentally friendly architect, when I started nobody was talking about the environment. I was interested in raw, cheap materials."];
const QuotesAuthorsArr = ["Renzo Piano", "Frank Gehry", "Frank Gehry","Jean Nouvel", "Shigeru Ban"]
var random = Math.floor(Math.random() * QuotesArr.length);

export default function Quotes() {
    const [animationTriggered, setAnimationTriggered] = useState(false);

    const handleWaypointEnter = () => {
      setAnimationTriggered(true);
    };
  
  return (
        <blockquote class="blockquote text-center" >
            <Waypoint onEnter={handleWaypointEnter}>
                <Fade duration={1000}>
                    <h4>Inšpirácia:</h4>
                    <p class="mb-0">{QuotesArr[random]}</p>
                    <footer><cite>{QuotesAuthorsArr[random]}</cite></footer>
                    <hr></hr>
                </Fade>
            </Waypoint>
        </blockquote>
  )
}

/*** End of Quotes.js ***/