import React, {useEffect, useState, useRef} from 'react';
import Footer from '../../../shared/Footer';
import "./projects-detail-page.css";
import { Fade } from 'react-reveal';
import { Waypoint } from 'react-waypoint';
import RodinnyDom1 from '../../../images/projects/rodinnydom2.jpg';
import RodinnyDom2 from '../../../images/projects/rodinnydom22.jpg';
import RodinnyDom3 from '../../../images/projects/rodinnydom23.jpg';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import {responsive} from './Carousel.js';

export default function PolusTowers() {
    document.title = "Rodinný dom II - kyska.sk";

    const [animationTriggered, setAnimationTriggered] = useState(false);

    const handleWaypointEnter = () => {
      setAnimationTriggered(true);
    };

    const carouselRef = useRef(null);

    const handlePrevClick = (event) => {
        event.preventDefault();
        carouselRef.current.previous();
    };

    const handleNextClick = (event) => {
        event.preventDefault();
        carouselRef.current.next();
    };

    return (
        <div>
            <Waypoint onEnter={handleWaypointEnter}>
            <Fade duration={1000}>
                <h3 className="top-heading"></h3>

                <div className='carousel-container'>
                    <Carousel
                        responsive={responsive}
                        infinite={true}
                        draggable={false}
                        ref={carouselRef}
                        autoPlay={10000}
                        arrows={false}
                        >
                            <img
                            className="img-fluid" 
                            src={RodinnyDom1}
                            alt="First slide"
                            />

                        <img
                        className="img-fluid"
                        src={RodinnyDom2}
                        alt="Second slide"
                        />


                        <img
                        className="img-fluid"
                        src={RodinnyDom3}
                        alt="Third slide"
                        />
                </Carousel>
                </div>

                <div className="carousel-controls">
                    <a
                        className="carousel-control-prev-a"
                        href="#carousel"
                        role="button"
                        onClick={handlePrevClick}
                    >
                        <span className="carousel-control-prev-icon" aria-hidden="true">
                            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="22" class="bi bi-chevron-compact-left" viewBox="0 0 16 16" preserveAspectRatio="none">
                                <path fill-rule="evenodd" d="M9.224 1.553a.5.5 0 0 1 .223.67L6.56 8l2.888 5.776a.5.5 0 1 1-.894.448l-3-6a.5.5 0 0 1 0-.448l3-6a.5.5 0 0 1 .67-.223z"/>
                            </svg>
                        </span>
                    </a>

                    <a
                        className="carousel-control-next-a"
                        href="#carousel"
                        role="button"
                        onClick={handleNextClick}
                    >
                        <span className="carousel-control-next-icon" aria-hidden="true">
                            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="22" class="bi bi-chevron-compact-right" viewBox="0 0 16 16" preserveAspectRatio="none">
                                <path fill-rule="evenodd" d="M6.776 1.553a.5.5 0 0 1 .671.223l3 6a.5.5 0 0 1 0 .448l-3 6a.5.5 0 1 1-.894-.448L9.44 8 6.553 2.224a.5.5 0 0 1 .223-.671z"/>
                            </svg>
                        </span>
                    </a>
                </div>

                
                <h3 className='detail-heading'>Rodinný dom II</h3>
                <hr className='detail-hr'/>
                <p>Realizácia rodinného domu v úzkej súčinnosti s majiteľmi pri návrhu a so zakomponovaním rôznych technických prvkov.</p>
                <br></br>

                <hr className="project-detail-hr"></hr>
                <div className="project-detail-table" style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>Rok realizácie</div>
                    <div>2018</div>
                </div>
                <hr className="project-detail-hr"></hr>

                <div className="project-detail-table" style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>Výkon</div>
                    <div>Kompletné inžinierske a projekčné práce</div>
                </div>
                <hr className="project-detail-hr"></hr>

                <div className="project-detail-table" style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>Stavebník</div>
                    <div>Fyzická osoba</div>
                </div>
                <hr className="project-detail-hr"></hr>

                <div className="project-detail-table" style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>Lokalita</div>
                    <div>Rakúsko</div>
                </div>
                <hr className="project-detail-hr"></hr>
                                
            </Fade>
            </Waypoint>

            <Footer />
        </div>
    );
}

