import React, {useEffect, useState, useRef} from 'react';
import Footer from '../../../shared/Footer';
import "./projects-detail-page.css";
import { Fade } from 'react-reveal';
import { Waypoint } from 'react-waypoint';
import Zelenka1 from '../../../images/projects/zelenka.jpg';

import Carousel from 'react-bootstrap/Carousel';

export default function PolusTowers() {
    document.title = "Bytový dom Zelenka - kyska.sk";

    const [animationTriggered, setAnimationTriggered] = useState(false);

    const handleWaypointEnter = () => {
      setAnimationTriggered(true);
    };

    const carouselRef = useRef(null);

    const handlePrevClick = (event) => {
        event.preventDefault();
        carouselRef.current.previous();
    };

    const handleNextClick = (event) => {
        event.preventDefault();
        carouselRef.current.next();
    };

    return (
        <div>
            <Waypoint onEnter={handleWaypointEnter}>
            <Fade duration={1000}>
                <h3 className="top-heading"></h3>

                <Carousel fade id="carousel" ref={carouselRef}>
                    <Carousel.Item>
                            <img
                            className="d-block w-100 img-fluid slideshow-img" 
                            src={Zelenka1}
                            alt="First slide"
                            />
                    </Carousel.Item>
                </Carousel>

                <h3 className='detail-heading'>Bytový dom Zelenka</h3>
                <hr className='detail-hr'/>
                <p>Objekt bol navrhovaný ako novostavba, s funkciou bývania a doplnkovej vybavenosti. Bytový dom bol riešený na bývanie so spoločenskými priestormi a občianskou vybavenosťou. Objekt mal harmonicky, funkčne aj hmotovo dotvoriť Záhradnícku ulicu, ako mestskú triedu. <br/><br/> Po dlhých peripetiách a zložitostí okolo projektu sa majiteľ rozhodol pre iný variant, bez súčinnosti nášho ateliéru. Projekt, v tejto forme ako bol navrhnutý nebude pokračovať a my mu želáme týmto veľa šťastia do budúcnosti.</p>
                <br></br>

                <hr className="project-detail-hr"></hr>
                <div className="project-detail-table" style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>Rok realizácie</div>
                    <div>2023</div>
                </div>
                <hr className="project-detail-hr"></hr>

                <div className="project-detail-table" style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>Výkon</div>
                    <div>Projekčné práce</div>
                </div>
                <hr className="project-detail-hr"></hr>

                <div className="project-detail-table" style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>Stavebník</div>
                    <div>Právnická osoba</div>
                </div>
                <hr className="project-detail-hr"></hr>

                <div className="project-detail-table" style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>Lokalita</div>
                    <div>Bratislava - Ružinov</div>
                </div>
                <hr className="project-detail-hr"></hr>
                                
            </Fade>
            </Waypoint>

            <Footer />
        </div>
    );
}

