/**
 * @file Footer.js
 * @author Martin Kubička (martinkubicka22@gmail.com)
 * @date 25.01.2023
 * 
 * @brief Declaration of footer component.
 */

import React from 'react'
import "./contactPageFooter.css"

export default function ContactPageFooter() {
  return (
    <footer className="footer-class-contact">
            <hr className="footer-hr"/>
            <p className="footer-info"><a className="footer-legal-info" href="/pravne-informacie">Právne informácie</a> | Všetky práva vyhradené Copyright © 2023 | KYSKA.architects s.r.o.</p>
    </footer>
  )
}

/*** End of Footer.js ***/
