/**
 * @file Contact.js
 * @author Martin Kubička (martinkubicka22@gmail.com)
 * @date 25.01.2023
 * 
 * @brief Declaration of main home page component.
 */

import React from 'react';
import Gif from './Gif';
import Quotes from './Quotes';
import News from './News';
import About from './About';
import Footer from '../../shared/Footer';

function Home() { 
  return (
    <div>
      <Gif/>
      <Quotes/>
      <About />
      <News />
      <Footer />
    </div>
  );
}

export default Home;

/*** End of Home.js ***/
