/**
 * @file Projects2021.js
 * @author Martin Kubička (martinkubicka22@gmail.com)
 * @date 25.01.2023
 * 
 * @brief Declaration of projects between 2021-2025 page component.
 */

import React, {useEffect} from 'react';
import "./projects-list.css"
import Zelenka from '../../images/projects/zelenka.jpg';
import RajeckeTeplice from '../../images/projects/rajecket.jpg';
import Ideo from '../../images/projects/IDEO/ideott.jpg';
import PlazaVivo from '../../images/projects/PlazaVIVO/vivott.jpg';
import Cei from '../../images/projects/CEI/ceitt.jpg';
import TvJoj from '../../images/projects/TVJoj/tvjojtt.jpg';
import Fitinnba from '../../images/projects/FITINNBratislava/fitinntt.jpg';
import Victory from '../../images/projects/Victory/victorytt.jpg';
import Mag from '../../images/projects/MAG/magtt.jpg';
import Semmering from '../../images/projects/Semmering/semmeringtt.jpg';
import Cunovo from '../../images/projects/cunovo.jpg';

function Projects2021() { 
  useEffect(() => {
    function handleResize() {
      const textHeight = document.querySelector('hr').offsetWidth;
      let images = document.querySelectorAll('.hover-blur a');
      
      const mediaQuery = window.matchMedia("(min-width: 900px)");
        
      if (mediaQuery.matches) {
        for(let i = 0; i < images.length; i++) {
            images[i].style.width = textHeight/3 -16+ 'px';
        }
      } else {
        for(let i = 0; i < images.length; i++) {
            images[i].style.width = textHeight + 'px';
        }
      }
}

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    }
}, []);

return (
  <div>
    <div className="row projects-list-img-div">
            <a className="projects-list-a" href="/projekty/semmering">
                <div className="hover-blur">
                <a href="/projekty/semmering" title="">
                    <img src={Semmering} alt="" className="img-fluid"/>
                    <h2><span className="text-white">Semmering</span></h2>
                </a>
                </div>
            </a>

            <a className="projects-list-a" href="/projekty/mag">
                <div className="hover-blur">
                <a href="/projekty/mag" title="">
                    <img src={Mag} alt="" className="img-fluid"/>
                    <h2><span className="text-white">Partnerstvo s MAG i4a-Group</span></h2>
                </a>
                </div>
            </a>

            <a className="projects-list-a" href="/projekty/victory">
                <div className="hover-blur">
                <a href="/projekty/victory" title="">
                    <img src={Victory} alt="" className="img-fluid"/>
                    <h2><span className="text-white">VICTORY RESIDENCE Polyfunkčný objekt</span></h2>
                </a>
                </div>
            </a>

            <a className="projects-list-a" href="/projekty/fitinn-bratislava">
                <div className="hover-blur">
                <a href="/projekty/fitinn-bratislava" title="">
                    <img src={Fitinnba} alt="" className="img-fluid"/>
                    <h2><span className="text-white">FITINN OC Kamenne námestie</span></h2>
                </a>
                </div>
            </a>

            <a className="projects-list-a" href="/projekty/tvjoj">
                <div className="hover-blur">
                <a href="/projekty/tvjoj" title="">
                    <img src={TvJoj} alt="" className="img-fluid"/>
                    <h2><span className="text-white">Televízia JOJ</span></h2>
                </a>
                </div>
            </a>

            <a className="projects-list-a" href="/projekty/cei">
                <div className="hover-blur">
                <a href="/projekty/cei" title="">
                    <img src={Cei} alt="" className="img-fluid"/>
                    <h2><span className="text-white">Centrum energetických informácií</span></h2>
                </a>
                </div>
            </a>

            <a className="projects-list-a" href="/projekty/plaza-vivo">
                <div className="hover-blur">
                <a href="/projekty/plaza-vivo" title="">
                    <img src={PlazaVivo} alt="" className="img-fluid"/>
                    <h2><span className="text-white">PLAZA VIVO! Bratislava</span></h2>
                </a>
                </div>
            </a>

            <a className="projects-list-a" href="/projekty/ideo">
                <div className="hover-blur">
                <a href="/projekty/ideo" title="">
                    <img src={Ideo} alt="" className="img-fluid"/>
                    <h2><span className="text-white">IDEO – Centrála firmy</span></h2>
                </a>
                </div>
            </a>

            <a className="projects-list-a" href="/projekty/cunovo">
                <div className="hover-blur">
                <a href="/projekty/cunovo" title="">
                    <img src={Cunovo} alt="" className="img-fluid"/>
                    <h2><span className="text-white">Čuňovo</span></h2>
                </a>
                </div>
            </a>

            <a className="projects-list-a" href="/projekty/rajecke-teplice">
                <div className="hover-blur">
                <a href="/projekty/rajecke-teplice" title="">
                    <img src={RajeckeTeplice} alt="" className="img-fluid"/>
                    <h2><span className="text-white">Rajecké teplice</span></h2>
                </a>
                </div>
            </a>

            <a className="projects-list-a" href="/projekty/zelenka">
                <div className="hover-blur">
                <a href="/projekty/zelenka" title="">
                    <img src={Zelenka} alt="" className="img-fluid"/>
                    <h2><span className="text-white">Zelenka</span></h2>
                </a>
                </div>
            </a>
    </div>
  </div>  
);
  }
  export default Projects2021;

/*** End of Projects2021.js ***/