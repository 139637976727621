/**
 * @file Gif.js
 * @author Martin Kubička (martinkubicka22@gmail.com)
 * @date 25.01.2023
 * 
 * @brief Declaration of randomly choosed gifs at home page.
 */

import React, { useState, useEffect } from 'react';
import winter from '../../images/winter.mp4'
import summer from '../../images/summer.mp4'
import authumn from '../../images/authumn.mp4'

export default function Gif() {
  const [video, setVideo] = useState('');

  useEffect(() => {
    const month = new Date().getMonth() + 1;

    if (month === 12 || (month >= 1 && month <= 3)) {
      setVideo(winter);
    } else if (month >= 4 && month <= 8) {
      setVideo(summer);
    } else {
      setVideo(authumn);
    }
  }, []);

  console.log(video);

  return (
    <video className="w-100" autoPlay loop muted playsInline>
      {video && <source src={video} type="video/mp4" />}
    </video>
  )
}

/*** End of Gif.js ***/