import './App.css';
import NavBar from './shared/NavBar';
import 'bootstrap/dist/css/bootstrap.css';
import React, { Component, useEffect }  from 'react';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Home from './pages/home/Home';
import AboutPage from './pages/about/About';
import Contact from './pages/contact/Contact';
import Projects from './pages/projects/Projects';
import Team from './pages/team/Team';
import Legal from './pages/legal/Legal';
import Fitinn from './pages/projects/detaiPages/Fitinn'
import RajeckeTeplice from './pages/projects/detaiPages/RajeckeTeplice'
import Zelenka from './pages/projects/detaiPages/Zelenka'
import Cunovo from './pages/projects/detaiPages/Cunovo'
import RodinnyDom1 from './pages/projects/detaiPages/RodinnyDom1'
import RodinnyDom2 from './pages/projects/detaiPages/RodinnyDom2'
import BytovyDomVieden from './pages/projects/detaiPages/BytovyDomVieden'
import PolusTowers from './pages/projects/detaiPages/PolusTowers'
import Dunajska54 from './pages/projects/detaiPages/Dunajska54'
import Brectanova from './pages/projects/detaiPages/Brectanova';
import Ideo from './pages/projects/detaiPages/Ideo';
import PlazaVivo from './pages/projects/detaiPages/PlazaVivo';
import Cei from './pages/projects/detaiPages/Cei';
import Tvjoj from './pages/projects/detaiPages/Tvjoj';
import Fitinnba from './pages/projects/detaiPages/Fitinnba';
import Victory from './pages/projects/detaiPages/Victory';
import Mag from './pages/projects/detaiPages/Mag';
import Semmering from './pages/projects/detaiPages/Semmering';

function App() { 
  useEffect(() => {
    if (window.location.pathname === "/kontakt") {
      document.querySelector(".wrapper").style.paddingBottom = "70px";
    } else if (window.location.pathname === "/pravne-informacie") {
      document.querySelector(".wrapper").style.paddingBottom = "0px";
    }
  }, []);

  return (
    <div className="App">
      <div className="wrapper">
      <Router>
        <NavBar/>
        <Routes>
          <Route path='/' exact element={<Home/>} />
          <Route path='/o-nas' element={<AboutPage/>} />
          <Route path='/kontakt' element={<Contact/>} />
          <Route path='/projekty' element={<Projects/>} />
          <Route path='/nas-team' element={<Team/>} />
          <Route path='/pravne-informacie' element={<Legal/>} />
          <Route path='/projekty/fitinn' element={<Fitinn/>} />
          <Route path='/projekty/rajecke-teplice' element={<RajeckeTeplice/>} />
          <Route path='/projekty/zelenka' element={<Zelenka/>} />
          <Route path='/projekty/cunovo' element={<Cunovo/>} />
          <Route path='/projekty/rodinny-dom-1' element={<RodinnyDom1/>} />
          <Route path='/projekty/rodinny-dom-2' element={<RodinnyDom2/>} />
          <Route path='/projekty/bytovy-dom-vieden' element={<BytovyDomVieden/>} />
          <Route path='/projekty/polus-towers' element={<PolusTowers/>} />
          <Route path='/projekty/dunajska-54' element={<Dunajska54/>} />
          <Route path='/projekty/brectanova' element={<Brectanova/>} />
          <Route path='/projekty/ideo' element={<Ideo/>} />
          <Route path='/projekty/plaza-vivo' element={<PlazaVivo/>} />
          <Route path='/projekty/cei' element={<Cei/>} />
          <Route path='/projekty/tvjoj' element={<Tvjoj/>} />
          <Route path='/projekty/fitinn-bratislava' element={<Fitinnba/>} />
          <Route path='/projekty/victory' element={<Victory/>} />
          <Route path='/projekty/mag' element={<Mag/>} />
          <Route path='/projekty/semmering' element={<Semmering/>} />
        </Routes>
      </Router>
      </div>
    </div>
  );
}

export default App;
