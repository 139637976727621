/**
 * @file About.js
 * @author Martin Kubička (martinkubicka22@gmail.com)
 * @date 25.01.2023
 * 
 * @brief Declaration of main about page component.
 */

import React, {useEffect, useState} from 'react';
import { Fade } from 'react-reveal';
import Footer from '../../shared/Footer';
import { Waypoint } from 'react-waypoint';
import './about.css';
import badge from "../../images/badge_logo.png";
import Partners from "./Partners"

function About() { 
    document.title = "O nás - kyska.sk";

    const [animationTriggered, setAnimationTriggered] = useState(false);

    const handleWaypointEnter = () => {
      setAnimationTriggered(true);
    };

    useEffect(() => {
        function handleResize() {
                const  navbarHeight = document.querySelector('.navbar').offsetHeight;
                let heading = document.querySelector('.top-heading');
                if (heading) {
                    heading.style.paddingTop = navbarHeight +'px';
                }
                

                let iconContainer = document.querySelector('.icon-container');
                if (iconContainer) {
                    if (window.innerWidth > 575) {
                        iconContainer.style.marginLeft = '';
                    } else if (window.innerWidth > 375 && window.innerWidth < 575) {
                        iconContainer.style.marginLeft = 100 + ((window.innerWidth - 375) / 2)+ "px" ;
                    } else {
                        iconContainer.style.marginLeft = 100 + "px";
                    }
                }

                let ulTitle = document.querySelector('.ul-title');
                if (ulTitle) {
                    if (window.innerWidth > 575) {
                        ulTitle.textContent = "Činnosti ateliéru:";
                    } else {
                        ulTitle.innerHTML = "<br />Činnosti ateliéru: ";
                    }
                }
        }
    
        handleResize();
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        }
    }, []);
    
    return (
        <div>
            <Waypoint onEnter={handleWaypointEnter}>
            <Fade duration={1000}>
            <h3 className="top-heading"></h3>
                
            <h4 className="about-heading">O nás</h4>
            <hr className="about-text-hr"/> 
            <p className="about-p">
                <div className="row">
                    <div className="col-sm-6">
                    Od nášho vzniku v roku 2009 sme navrhli a zrealizovali množstvo projektov rôzneho charakteru, menšej a strednej veľkosti. Podieľali sme sa na realizácií tzv. drobnej architektúry, či rodinných alebo bytových domov. Vo vnímaní životného (verejného) priestoru sa stotožňujeme s myšlienkou viedenského architekta H. Holleina (*1934  - †2014) „Everything is architecture“. Za projektami a realizáciami je podpora kolegov a konzultantov z rôznych oblastí, s ktorými dlhodobo spolupracujeme.
                    </div>
                    
                    <div className="col-sm-6">
                        <div className="ul-title">
                            
                        </div>
                        <ul className="list-unstyled">
                            <li className="bullet-li"><hr></hr>
                                poradenstvo
                            </li>
                            <li className="bullet-li"><hr></hr>
                                vypracovanie architektonického návrhu, resp. štúdie
                            </li>
                            <li className="bullet-li"><hr></hr>
                                vypracovanie podkladov pre všetky stupne stavebných konaní
                            </li>
                            <li className="bullet-li"><hr></hr>
                                vypracovanie technickej „due diligence“</li>
                            <li className="bullet-li"><hr></hr>
                                vypracovanie „feasibility study“</li>
                            <li className="bullet-li"><hr></hr>
                                vypracovanie realizačnej a tendrovej dokumentácie</li>
                            <li className="bullet-li"><hr></hr>
                                vypracovanie rozpočtu a výkazu výmer</li>
                            <li className="bullet-li"><hr></hr>
                                vypracovanie profesionálnych, fotorealistických vizualizácií</li>
                            <li className="bullet-li"><hr></hr>
                                BIM modelovanie</li>
                            <li className="bullet-li"><hr></hr>
                                inžiniering k vyššie uvedenému</li>
                            <li className="bullet-li"><hr></hr>
                                sprostredkovanie predaja nehnuteľností</li>
                        </ul>  
                    </div>

                </div>
                
                Vnímame ako výzvu:
                <div className="row icon-container">
                        <div className="col-sm-2 icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-arrows-fullscreen" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M5.828 10.172a.5.5 0 0 0-.707 0l-4.096 4.096V11.5a.5.5 0 0 0-1 0v3.975a.5.5 0 0 0 .5.5H4.5a.5.5 0 0 0 0-1H1.732l4.096-4.096a.5.5 0 0 0 0-.707zm4.344 0a.5.5 0 0 1 .707 0l4.096 4.096V11.5a.5.5 0 1 1 1 0v3.975a.5.5 0 0 1-.5.5H11.5a.5.5 0 0 1 0-1h2.768l-4.096-4.096a.5.5 0 0 1 0-.707zm0-4.344a.5.5 0 0 0 .707 0l4.096-4.096V4.5a.5.5 0 1 0 1 0V.525a.5.5 0 0 0-.5-.5H11.5a.5.5 0 0 0 0 1h2.768l-4.096 4.096a.5.5 0 0 0 0 .707zm-4.344 0a.5.5 0 0 1-.707 0L1.025 1.732V4.5a.5.5 0 0 1-1 0V.525a.5.5 0 0 1 .5-.5H4.5a.5.5 0 0 1 0 1H1.732l4.096 4.096a.5.5 0 0 1 0 .707z"/>
                            </svg>
                            <br/>
                            flexibilitu
                        </div>
                    
                        <div className="col-sm-2 icon-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-speedometer2" viewBox="0 0 16 16">
                                <path d="M8 4a.5.5 0 0 1 .5.5V6a.5.5 0 0 1-1 0V4.5A.5.5 0 0 1 8 4zM3.732 5.732a.5.5 0 0 1 .707 0l.915.914a.5.5 0 1 1-.708.708l-.914-.915a.5.5 0 0 1 0-.707zM2 10a.5.5 0 0 1 .5-.5h1.586a.5.5 0 0 1 0 1H2.5A.5.5 0 0 1 2 10zm9.5 0a.5.5 0 0 1 .5-.5h1.5a.5.5 0 0 1 0 1H12a.5.5 0 0 1-.5-.5zm.754-4.246a.389.389 0 0 0-.527-.02L7.547 9.31a.91.91 0 1 0 1.302 1.258l3.434-4.297a.389.389 0 0 0-.029-.518z"/>
                                <path fill-rule="evenodd" d="M0 10a8 8 0 1 1 15.547 2.661c-.442 1.253-1.845 1.602-2.932 1.25C11.309 13.488 9.475 13 8 13c-1.474 0-3.31.488-4.615.911-1.087.352-2.49.003-2.932-1.25A7.988 7.988 0 0 1 0 10zm8-7a7 7 0 0 0-6.603 9.329c.203.575.923.876 1.68.63C4.397 12.533 6.358 12 8 12s3.604.532 4.923.96c.757.245 1.477-.056 1.68-.631A7 7 0 0 0 8 3z"/>
                            </svg>
                            <br/>
                            rýchlosť
                        </div>
                        
                        <div className="col-sm-2 icon-3">
                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-tree" viewBox="0 0 16 16">
                                <path d="M8.416.223a.5.5 0 0 0-.832 0l-3 4.5A.5.5 0 0 0 5 5.5h.098L3.076 8.735A.5.5 0 0 0 3.5 9.5h.191l-1.638 3.276a.5.5 0 0 0 .447.724H7V16h2v-2.5h4.5a.5.5 0 0 0 .447-.724L12.31 9.5h.191a.5.5 0 0 0 .424-.765L10.902 5.5H11a.5.5 0 0 0 .416-.777l-3-4.5zM6.437 4.758A.5.5 0 0 0 6 4.5h-.066L8 1.401 10.066 4.5H10a.5.5 0 0 0-.424.765L11.598 8.5H11.5a.5.5 0 0 0-.447.724L12.69 12.5H3.309l1.638-3.276A.5.5 0 0 0 4.5 8.5h-.098l2.022-3.235a.5.5 0 0 0 .013-.507z"/>
                            </svg>
                        <br/>
                                udržateľnosť
                        </div>

                        <div className="col-sm-2 icon-4">
                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-rulers" viewBox="0 0 16 16">
                                <path d="M1 0a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h5v-1H2v-1h4v-1H4v-1h2v-1H2v-1h4V9H4V8h2V7H2V6h4V2h1v4h1V4h1v2h1V2h1v4h1V4h1v2h1V2h1v4h1V1a1 1 0 0 0-1-1H1z"/>
                            </svg>
                            <br/>
                                estetiku
                        </div>

                </div>
                Pripravujeme projekty, konania a súvisiace procesy v Rakúsku a Českej republike, oboje na báze dlhodobého partnerstva a spolupráce s architektonickými ateliérmi v uvedených krajinách. Našim primárnym cieľom je vytvárať pre našich klientov, racionálne, hodnotné a v neposlednom rade aj pekné diela. 
            </p>

            <h4 className="about-heading">Naši klienti</h4>
            <hr className="about-text-hr"/> 

            <Partners />

            </Fade>
            </Waypoint>

            <Footer />
        </div>
    );
  }
     
  export default About;

/*** End of About.js ***/
