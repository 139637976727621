import React, {useEffect, useState, useRef} from 'react';
import Footer from '../../../shared/Footer';
import "./projects-detail-page.css";
import { Fade } from 'react-reveal';
import { Waypoint } from 'react-waypoint';
import cei0 from '../../../images/projects/CEI/ceit.png';
import cei1 from '../../../images/projects/CEI/cei1.png';
import cei2 from '../../../images/projects/CEI/cei2.png';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import {responsive} from './Carousel.js';

export default function Cei() {
    document.title = "Centrum energetických informácií - kyska.sk";

    const [animationTriggered, setAnimationTriggered] = useState(false);

    const handleWaypointEnter = () => {
      setAnimationTriggered(true);
    };

    const carouselRef = useRef(null);

    const handlePrevClick = (event) => {
        event.preventDefault();
        carouselRef.current.previous();
    };

    const handleNextClick = (event) => {
        event.preventDefault();
        carouselRef.current.next();
    };

    return (
        <div>
            <Waypoint onEnter={handleWaypointEnter}>
            <Fade duration={1000}>
                <h3 className="top-heading"></h3>

                <div className='carousel-container'>
                    <Carousel
                        responsive={responsive}
                        infinite={true}
                        draggable={false}
                        ref={carouselRef}
                        autoPlay={10000}
                        arrows={false}
                        >

                        <img
                            className="img-fluid"
                            src={cei0}
                            alt="First slide"
                        />

                        <img
                            className="img-fluid"
                            src={cei1}
                            alt="Second slide"
                        />
                        
                        <img
                            className="img-fluid"
                            src={cei2}
                            alt="Third slide"
                        />
                    </Carousel>
                </div>

                <div className="carousel-controls">
                    <a
                        className="carousel-control-prev-a"
                        href="#carousel"
                        role="button"
                        onClick={handlePrevClick}
                    >
                        <span className="carousel-control-prev-icon" aria-hidden="true">
                            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="22" class="bi bi-chevron-compact-left" viewBox="0 0 16 16" preserveAspectRatio="none">
                                <path fill-rule="evenodd" d="M9.224 1.553a.5.5 0 0 1 .223.67L6.56 8l2.888 5.776a.5.5 0 1 1-.894.448l-3-6a.5.5 0 0 1 0-.448l3-6a.5.5 0 0 1 .67-.223z"/>
                            </svg>
                        </span>
                    </a>

                    <a
                        className="carousel-control-next-a"
                        href="#carousel"
                        role="button"
                        onClick={handleNextClick}
                    >
                        <span className="carousel-control-next-icon" aria-hidden="true">
                            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="22" class="bi bi-chevron-compact-right" viewBox="0 0 16 16" preserveAspectRatio="none">
                                <path fill-rule="evenodd" d="M6.776 1.553a.5.5 0 0 1 .671.223l3 6a.5.5 0 0 1 0 .448l-3 6a.5.5 0 1 1-.894-.448L9.44 8 6.553 2.224a.5.5 0 0 1 .223-.671z"/>
                            </svg>
                        </span>
                    </a>
                </div>

                
                <h3 className='detail-heading'>Centrum energetických informácií</h3>
                <hr className='detail-hr'/>
                <p>Na začiatku spracovania projektu stála výzva navrhnúť malú univerzálnu prenajímateľnú halu. V procese návrhu prejavila jedna súkromná spoločnosť zámer umiestniť v objekte svoj HQ. Základným zámerom je vytvoriť objekt s vysokým  moderným štandardom využitia budovy v smere funkčnom aj prevádzkovom s cieľom dosiahnutia maximálneho komfortu pre užívateľov. Dôraz je aj na jednoduchosť a hospodárnosť návrhu pri zachovaní si jeho architektonickej kvality. Prídavná funkcia bude multifunkčné využitie budovy. Estetické pôsobenie objektu bude zvýraznené členením plôch fasád s využitím možností farebnej kompozície a iluminácie objektu.</p>
                <br></br>

                <hr className="project-detail-hr"></hr>
                <div className="project-detail-table" style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>Rok realizácie</div>
                    <div>2023</div>
                </div>
                <hr className="project-detail-hr"></hr>

                <div className="project-detail-table" style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>Výkon</div>
                    <div>Projekčné práce v spolupráci s ateliérom VINGS.</div>
                </div>
                <hr className="project-detail-hr"></hr>

                <div className="project-detail-table" style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>Stavebník</div>
                    <div>Právnická osoba</div>
                </div>
                <hr className="project-detail-hr"></hr>

                <div className="project-detail-table" style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>Lokalita</div>
                    <div>Bratislava - Čunovo</div>
                </div>
                <hr className="project-detail-hr"></hr>
                                
            </Fade>
            </Waypoint>

            <Footer />
        </div>
    );
}

