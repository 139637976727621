/**
 * @file Projects.js
 * @author Martin Kubička (martinkubicka22@gmail.com)
 * @date 25.01.2023
 * 
 * @brief Declaration of main projects page component.
 */

import React, {useEffect, useState} from 'react';
import Footer from '../../shared/Footer';
import "./projects.css"
import { Fade } from 'react-reveal';
import { Waypoint } from 'react-waypoint';
import All from "./All";
import Projects2010 from './Projects2010';
import Projects2016 from './Projects2016';
import Projects2021 from './Projects2021';

function Projects() { 
    const [animationTriggered, setAnimationTriggered] = useState(false);

    const handleWaypointEnter = () => {
      setAnimationTriggered(true);
    };

    useEffect(() => {
        document.title = "Projekty - kyska.sk";
        function handleResize() {
                const  navbarHeight = document.querySelector('.navbar').offsetHeight;
                let heading = document.querySelector('.top-heading');
                if (heading) {
                    heading.style.paddingTop = navbarHeight +'px';
                }
        }
    
        handleResize();
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        }
    }, []);

    return (
      <div>
        <Waypoint onEnter={handleWaypointEnter}>
        <Fade duration={1000}>
    
        <h3 className="top-heading"></h3>

        <div className='projects-menu-container'>
        <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
            <li className="nav-item">
                <a className="nav-link active" id="pills-all-tab" data-toggle="pill" href="#pills-all" role="tab" aria-controls="pills-all" aria-selected="true">Všetko</a>
            </li>
            <li className="nav-item">
                <a className="nav-link nav-link-secondary" id="pills-2010-2015-tab" data-toggle="pill" href="#pills-2010-2015" role="tab" aria-controls="pills-2010-2015" aria-selected="false">2010-2015</a>
            </li>
            <li className="nav-item">
                <a className="nav-link nav-link-secondary" id="pills-2016-2020-tab" data-toggle="pill" href="#pills-2016-2020" role="tab" aria-controls="pills-2016-2020" aria-selected="false">2016-2020</a>
            </li>
            <li className="nav-item">
                <a className="nav-link nav-link-secondary" id="pills-2021-2025-tab" data-toggle="pill" href="#pills-2021-2025" role="tab" aria-controls="pills-2021-2025" aria-selected="false">2021-2025</a>
            </li>
        </ul>
        </div>
        
        <hr className="projects-hr"/>

        <div className="tab-content" id="pills-tabContent">
            <div className="tab-pane fade show active" id="pills-all" role="tabpanel" aria-labelledby="pills-all-tab">
                <All />
            </div>
            
            <div className="tab-pane fade" id="pills-2010-2015" role="tabpanel" aria-labelledby="pills-2010-2015-tab">
                <Projects2010 />
            </div>
            
            <div className="tab-pane fade" id="pills-2016-2020" role="tabpanel" aria-labelledby="pills-2016-2020-tab">
                <Projects2016 />
            </div>

            <div className="tab-pane fade" id="pills-2021-2025" role="tabpanel" aria-labelledby="pills-2021-2025-tab">
                <Projects2021 />
            </div>
        </div>

        </Fade>
        </Waypoint>

        <Footer />
      </div>
    );
  }
  export default Projects;

/*** End of Projects.js ***/