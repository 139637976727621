import React, {useEffect, useState, useRef} from 'react';
import Footer from '../../../shared/Footer';
import "./projects-detail-page.css";
import { Fade } from 'react-reveal';
import { Waypoint } from 'react-waypoint';
import brectanova0 from '../../../images/projects/Brectanova/brectanovat.jpg';
import brectanova1 from '../../../images/projects/Brectanova/brectanova1.jpg';
import brectanova2 from '../../../images/projects/Brectanova/brectanova2.jpg';
import brectanova4 from '../../../images/projects/Brectanova/brectanova4.jpg';
import brectanova5 from '../../../images/projects/Brectanova/brectanova5.jpg';
import brectanova7 from '../../../images/projects/Brectanova/brectanova7.jpg';
import brectanova9 from '../../../images/projects/Brectanova/brectanova9.jpg';
import brectanova10 from '../../../images/projects/Brectanova/brectanova10.jpg';
import brectanova11 from '../../../images/projects/Brectanova/brectanova11.jpg';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import {responsive} from './Carousel.js';

export default function Brectanova() {
    document.title = "Interiér „penthouse“ - kyska.sk";

    const [animationTriggered, setAnimationTriggered] = useState(false);

    const handleWaypointEnter = () => {
      setAnimationTriggered(true);
    };

    const carouselRef = useRef(null);

    const handlePrevClick = (event) => {
        event.preventDefault();
        carouselRef.current.previous();
    };

    const handleNextClick = (event) => {
        event.preventDefault();
        carouselRef.current.next();
    };

    return (
        <div>
            <Waypoint onEnter={handleWaypointEnter}>
            <Fade duration={1000}>
                <h3 className="top-heading"></h3>

                <div className='carousel-container'>
                    <Carousel
                        responsive={responsive}
                        infinite={true}
                        draggable={false}
                        ref={carouselRef}
                        autoPlay={10000}
                        arrows={false}
                        >

                        <img
                            className="img-fluid"
                            src={brectanova0}
                            alt="First slide"
                        />

                        <img
                            className="img-fluid"
                            src={brectanova1}
                            alt="Second slide"
                        />
                        
                        <img
                            className="img-fluid"
                            src={brectanova2}
                            alt="Third slide"
                        />
                    


                        <img
                            className="img-fluid"
                            src={brectanova4}
                            alt="Fourth slide"
                        />

                        <img
                            className="img-fluid"
                            src={brectanova5}
                            alt="Fourth slide"
                        />

                        <img
                            className="img-fluid"
                            src={brectanova7}
                            alt="Fourth slide"
                        />



                        <img
                            className="img-fluid"
                            src={brectanova9}
                            alt="Fourth slide"
                        />

                        <img
                            className="img-fluid"
                            src={brectanova10}
                            alt="Fourth slide"
                        />

                        <img
                            className="img-fluid"
                            src={brectanova11}
                            alt="Fourth slide"
                        />
                    </Carousel>
                </div>

                <div className="carousel-controls">
                    <a
                        className="carousel-control-prev-a"
                        href="#carousel"
                        role="button"
                        onClick={handlePrevClick}
                    >
                        <span className="carousel-control-prev-icon" aria-hidden="true">
                            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="22" class="bi bi-chevron-compact-left" viewBox="0 0 16 16" preserveAspectRatio="none">
                                <path fill-rule="evenodd" d="M9.224 1.553a.5.5 0 0 1 .223.67L6.56 8l2.888 5.776a.5.5 0 1 1-.894.448l-3-6a.5.5 0 0 1 0-.448l3-6a.5.5 0 0 1 .67-.223z"/>
                            </svg>
                        </span>
                    </a>

                    <a
                        className="carousel-control-next-a"
                        href="#carousel"
                        role="button"
                        onClick={handleNextClick}
                    >
                        <span className="carousel-control-next-icon" aria-hidden="true">
                            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="22" class="bi bi-chevron-compact-right" viewBox="0 0 16 16" preserveAspectRatio="none">
                                <path fill-rule="evenodd" d="M6.776 1.553a.5.5 0 0 1 .671.223l3 6a.5.5 0 0 1 0 .448l-3 6a.5.5 0 1 1-.894-.448L9.44 8 6.553 2.224a.5.5 0 0 1 .223-.671z"/>
                            </svg>
                        </span>
                    </a>
                </div>

                
                <h3 className='detail-heading'>Interiér „penthouse“</h3>
                <hr className='detail-hr'/>
                <p>Majiteľ sa rozhodol pre kúpu holo bytu v stave „shell and core“. Po zhodnotení možností a limitov jestvujúcej stavby bol spracovaný návrh na realizáciu interiéru. Dispozícia zostala zachovaná, podporil sa presvetlený charakter bytu.  Tento návrh bol aj dôsledne realizovaný a dozorovaný. Výsledkom je komfortný obytný priestor so zabudovanými high-tech prvkami  a nádhernou terasou.</p>
                <br></br>

                <hr className="project-detail-hr"></hr>
                <div className="project-detail-table" style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>Rok realizácie</div>
                    <div>2010</div>
                </div>
                <hr className="project-detail-hr"></hr>

                <div className="project-detail-table" style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>Výkon</div>
                    <div>Architektonický dozor, projekčné a koordinačné práce v spolupráci s ateliérom PALME. - Viedeň </div>
                </div>
                <hr className="project-detail-hr"></hr>

                <div className="project-detail-table" style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>Stavebník</div>
                    <div>Fyzická osoba</div>
                </div>
                <hr className="project-detail-hr"></hr>

                <div className="project-detail-table" style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>Lokalita</div>
                    <div>Bratislava - Nové Mesto</div>
                </div>
                <hr className="project-detail-hr"></hr>
                                
            </Fade>
            </Waypoint>

            <Footer />
        </div>
    );
}

