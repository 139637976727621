/**
 * @file News.js
 * @author Martin Kubička (martinkubicka22@gmail.com)
 * @date 25.01.2023
 * 
 * @brief Declaration of news component at home page.
 */

import React, {useEffect, useState} from 'react'
import "./news.css"
import news1 from "../../images/news/news1.jpg"
import news2 from "../../images/news/news2.jpg"
import news3 from "../../images/news/news3.jpg"
import news4 from "../../images/news/news4.jpeg"
import news5 from "../../images/news/news5.jpeg"
import news6 from "../../images/news/news6.jpg"
import { Waypoint } from 'react-waypoint';
import Fade from 'react-reveal/Fade';

export default function News() {
    const [animationTriggered, setAnimationTriggered] = useState(false);

    const handleWaypointEnter = () => {
      setAnimationTriggered(true);
    };


    useEffect(() => {
        function handleResize() {
          const textHeight = document.querySelector('.about-container').offsetWidth;
          let images = document.querySelectorAll('.news img');
          
          const mediaQuery = window.matchMedia("(min-width: 900px)");
            if (mediaQuery.matches) {
                for(let i = 0; i < images.length; i++) {
                    images[i].style.width = textHeight - 400 + 'px';
                }
            } else {
                for(let i = 0; i < images.length; i++) {
                    images[i].style.width = textHeight - 40 + 'px';
                }
            }
        }
    
        handleResize();
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        }
    }, []);

  return (
    <div class="news">
        <Waypoint onEnter={handleWaypointEnter}>
        <Fade duration={1000}>
        <h3>Novinky</h3>

        <p className="date-news">18.7.2023</p>
        <img src={news1} style={{maxWidth: "600px"}}></img>
        <p className="news-text">V lete v roku 2024 sme oslávili 15 rokov od založenia firmy.</p>

        <hr />

        <p className="date-news">21.2.2024</p>
        <img src={news2}></img>
        <p className="news-text">Projekt Semmering, úspešne napredujeme v projektoch v rakúskom Štajersku kde sme sa projektom snažili rešpektovať regionálnu  architektúru génius loci.</p>

        <hr />

        <p className="date-news">1.10.2023</p>
        <img src={news3}></img>
        <p className="news-text">Úzko spolupracujeme na niekoľkých projektoch s Viedenským ateliérom MAG a investičným holdingom i4a-group.</p>

        <hr />

        <p className="date-news">8.8.2023</p>
        <img src={news4}></img>
        <p className="news-text">Otvorenie najväčšieho fitness štúdia siete FITINN v Bratislave.</p>

        <hr />

        <p className="date-news">10.7.2023</p>
        <img src={news5}></img>
        <p className="news-text">Otvorenie rekonštruovaného námestia plaza VIVO!</p>

        <hr />

        <p className="date-news">1.6.2023</p>
        <img src={news6}></img>
        <p className="news-text">V pátraní po autorovi architektonických návrhov - originálov z roku 1878 v našom vlastníctve, sme s veľkým prekvapením a potešením zistili, že autorom je významný architekt Imrich Steindl (maďarsky Steindl Imre; * 29. október 1839 – † 31. august 1902, Budapešť, Maďarsko). Korunou jeho tvorby je budapeštiansky parlament, ktorý je svetoznámy a vďaka nemu sa nikdy nezabudne na jeho staviteľa Steindla.</p>

        </Fade>
        </Waypoint>
    </div>
  )
}

/*** End of News.js ***/