import React from 'react';
import Atp from '../../images/clients/ATP.png';
import Danicon from '../../images/clients/DANICON.png';
import Fitinn from '../../images/clients/FITINN.png';
import Immofinanz from '../../images/clients/IMMOFINANZ.jpeg';
import Teichmann from '../../images/clients/TEICHMANN.png';
import Yit from '../../images/clients/YIT.png';
import Vermont from '../../images/clients/VERMONT.png';
import Vivo from '../../images/clients/VIVO.png';
import StopShop from '../../images/clients/STOPSHOP.png'
import Joj from '../../images/clients/JOJ.jpg'
import "./partners.css"

function Partners() { 
    return (
        <div className="row partners-img-div">
            <img src={Atp} className="partners-img img-fluid"/>

            <img src={Fitinn} className="partners-img img-fluid"/>

            <img src={Immofinanz} className="partners-img img-fluid" />

            <img src={Teichmann} className="partners-img img-fluid"/>

            <img src={Yit} className="partners-img img-fluid"/>

            <img src={Vermont} className="partners-img img-fluid"/>

            <img src={Vivo} className="partners-img img-fluid"/>

            <img src={StopShop} className="partners-img img-fluid" style={{maxHeight: "80px", maxWidth: "100px", marginTop: "20px", borderRadius: "0px"}}/>

            <img src={Joj} className="partners-img img-fluid" style={{maxHeight: "110px", maxWidth: "110px"}}/>
        </div>
    );
  }
     
  export default Partners;
