/**
 * @file About.js
 * @author Martin Kubička (martinkubicka22@gmail.com)
 * @date 25.01.2023
 * 
 * @brief Declaration of about component at home page.
 */

import React, {useEffect, useState} from 'react'
import "./about.css"
import badge from "../../images/badge_logo.png"
import Fade from 'react-reveal/Fade';
import {Waypoint} from 'react-waypoint';


export default function About() {
    const [animationTriggered, setAnimationTriggered] = useState(false);

    const handleWaypointEnter = () => {
      setAnimationTriggered(true);
    };

  return (
    <Waypoint onEnter={handleWaypointEnter}>
    <Fade duration={1000}>
        <div className="about-container">
            <p className="badge-img">
                Od nášho vzniku v roku 2009 sme navrhli a zrealizovali množstvo projektov rôzneho charakteru, menšej a strednej veľkosti. Podieľali sme sa na realizácií tzv. drobnej architektúry, či rodinných alebo bytových domov. Vo vnímaní životného (verejného) priestoru sa stotožňujeme s myšlienkou viedenského architekta H. Holleina (*1934  - †2014) „Everything is architecture“.
            </p>
        </div>
    </Fade>
    </Waypoint>
  )
}

/*** End of About.js ***/